<template>
  <div></div>
</template>

<script>
export default {
  watch: {
    $route: {
      handler() {
        this.$ability.update(
          this.$store.getters['auth/permissions'].map(scope => {
            return {
              subject: 'all',
              action: scope
            };
          })
        );
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
