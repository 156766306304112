var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"relative z-20 h-12 w-12 rounded-full overflow-hidden border-2 border-green-400 focus:border-green-700 focus:outline-none",attrs:{"type":"button"},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('img',{staticClass:"h-full w-full object-cover",attrs:{"src":require('../assets/images/user.png'),"alt":""}})]),(_vm.isOpen)?_c('button',{staticClass:"fixed z-10 inset-0 h-full w-full bg-black opacity-50 cursor-default",attrs:{"tabindex":"-1"},on:{"click":function($event){_vm.isOpen = false}}}):_vm._e(),_c('div',{staticClass:"hidden sm:block px-4 py-1 w-64 leading-4"},[_c('p',{staticClass:"font-bold text-lg mb-2 text-green-700"},[_vm._v(_vm._s(_vm.user.name))]),_c('p',{staticClass:"font-bold text-sm text-gray-600"},[_vm._v(" "+_vm._s(_vm.user.role.label)+" "),(_vm.user.role.name === 'participant' || _vm.user.role.name === 'Instructor')?_c('toggle-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content:
            'Altere sua disponibilidade para que recrutadores encontre seu currículo.',
          placement: 'bottom'
        }),expression:"{\n          content:\n            'Altere sua disponibilidade para que recrutadores encontre seu currículo.',\n          placement: 'bottom'\n        }"}],staticClass:"float-right",attrs:{"value":_vm.isAvailable,"width":110,"height":23,"font-size":14,"color":{ checked: '#007A6F', unchecked: '#A1A1A1' },"labels":{ checked: 'Disponível', unchecked: 'Indisponível' }},on:{"change":_vm.toggleAvailableStatus}}):_vm._e(),(
            _vm.$attrs.userAccess.status == 2 && _vm.user.role.name === 'associate'
          )?_c('span',{staticClass:"font-bold"},[_vm._v("- Acesso Temporário")]):_vm._e()],1),(
          !_vm.$attrs.userAccess.access.isRevoke &&
            _vm.user.role.name === 'associate' &&
            _vm.$attrs.userAccess.status == 2
        )?_c('p',{staticClass:"text-sm font-light"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: `Seu acesso é provisório e após ${this.$attrs.userAccess.access.diference} dias suas ações
            dentro do sistema serão limitadas.
            <br>
            Entre em contato com o seu Sindicato para liberação do seu acesso definitivo.
            <br><br>
            Sindicato: ${this.$attrs.userAccess.informations.syndicate.name}
            <br>
            Contato: ${this.$attrs.userAccess.informations.syndicate.telephone}`,
            placement: 'left'
          }),expression:"{\n            content: `Seu acesso é provisório e após ${this.$attrs.userAccess.access.diference} dias suas ações\n            dentro do sistema serão limitadas.\n            <br>\n            Entre em contato com o seu Sindicato para liberação do seu acesso definitivo.\n            <br><br>\n            Sindicato: ${this.$attrs.userAccess.informations.syndicate.name}\n            <br>\n            Contato: ${this.$attrs.userAccess.informations.syndicate.telephone}`,\n            placement: 'left'\n          }"}],staticClass:"ri-information-line mr-1"}),_c('span',[_vm._v("Restam "+_vm._s(_vm.$attrs.userAccess.access.diference)+" dias")])]):_vm._e()]),_c('notification')],1),(_vm.isOpen)?_c('div',{staticClass:"absolute z-20 right-0 sm:left-0 mt-2 w-64 flex flex-col bg-green-700 rounded-lg text-white overflow-hidden shadow-xl"},[_c('div',{staticClass:"block sm:hidden px-4 py-2 bg-gray-100 border-b border-white"},[_c('p',{staticClass:"font-bold mb-4 text-green-700 text-xl"},[_vm._v(_vm._s(_vm.user.name))]),_c('p',{staticClass:"font-bold text-gray-600 text-xl"},[_vm._v(_vm._s(_vm.user.role.label))])]),_c('router-link',{staticClass:"px-4 py-2 hover:bg-green-400 hover:cursor-pointer",attrs:{"to":{ name: 'my-access' }}},[_vm._v(" Meu Acesso ")]),(
        _vm.user.role.name === 'participant' || _vm.user.role.name === 'Instructor'
      )?_c('router-link',{staticClass:"px-4 py-2 hover:bg-green-400 hover:cursor-pointer",attrs:{"to":{ name: 'my-profile' }}},[_vm._v(" Meu Perfil ")]):_vm._e(),(
        _vm.user.role.name === 'participant' || _vm.user.role.name === 'Instructor'
      )?_c('router-link',{staticClass:"px-4 py-2 hover:bg-green-400 hover:cursor-pointer",attrs:{"to":{ name: 'my-address' }}},[_vm._v(" Meu Endereço ")]):_vm._e(),(_vm.$can('update_curriculum', 'all'))?_c('router-link',{staticClass:"px-4 py-2 hover:bg-green-400 hover:cursor-pointer",attrs:{"to":{ name: 'my-complete-curriculum' }}},[_vm._v(" Meu Currículo ")]):_vm._e(),_c('button',{staticClass:"text-left px-4 py-2 hover:bg-green-400 hover:cursor-pointer focus:outline-none",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.logoutAndRedirect.apply(null, arguments)}}},[_vm._v(" Sair ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }