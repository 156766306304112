<template>
  <aside
    :class="expanded ? 'w-72' : 'w-20'"
    class="h-full flex flex-col items-center justify-between py-4 bg-gradient-to-b from-green-1003 to-green-1002 text-white"
  >
    <img
      :class="expanded ? 'px-10' : 'px-4'"
      class="py-6"
      :src="logo"
      alt="Logotipo"
    />
    <ul class="scroller w-full flex-grow pl-2 overflow-y-scroll">
      <side-menu-item
        v-if="$can('create_search', 'all')"
        route-name="search"
        icon="ri-search-line"
        title="Iniciar Colheita"
      ></side-menu-item>

      <side-menu-item
        v-if="$can('list_associates', 'all')"
        route-name="action-plan"
        icon="ri-book-2-line"
        title="Lista de Interessados"
      ></side-menu-item>

      <side-menu-item
        v-if="user.role.name === 'associate'"
        route-name="favorites"
        icon="ri-user-star-line"
        title="Talentos Favoritos"
      ></side-menu-item>

      <side-menu-item
        v-if="
          user.role.name === 'participant' ||
            user.role.name === 'Instructor' ||
            user.role.name === 'associate'
        "
        route-name="dashboard"
        icon="ri-dashboard-line"
        title="Agenda de Cursos"
      ></side-menu-item>

      <side-menu-item
        v-if="
          user.role.name === 'participant' || user.role.name === 'Instructor'
        "
        route-name="search-vacancies"
        icon="ri-user-search-line"
        title="Buscar Vaga"
      ></side-menu-item>
      <side-menu-item
        v-if="
          user.role.name === 'participant' || user.role.name === 'Instructor'
        "
        route-name="my-vacancies"
        icon="ri-star-s-fill"
        title="Minhas Vagas"
      ></side-menu-item>

      <side-menu-item
        v-if="$can('list_courses', 'all')"
        route-name="course"
        icon="ri-book-2-line"
        title="Cursos"
      ></side-menu-item>

      <side-menu-item
        v-if="user.role.name === 'administrator'"
        route-name="list-ads"
        icon="ri-flag-line"
        title="Banners"
      ></side-menu-item>

      <side-menu-item
        v-if="$can('list_acceptances', 'all')"
        route-name="list-terms"
        icon="ri-file-text-line"
        title="LGPD"
      ></side-menu-item>

      <side-menu-item
        v-if="$can('list_associates', 'all')"
        route-name="list-associates"
        icon="ri-service-line"
        title="Associados"
      ></side-menu-item>

      <side-menu-item
        v-if="$can('regionals:read', 'all')"
        route-name="list-regionals"
        icon="ri-team-line"
        title="Regionais"
      ></side-menu-item>

      <side-menu-item
        v-if="$can('list_syndicates', 'all')"
        route-name="list-syndicates"
        icon="ri-building-line"
        title="Sindicatos"
      ></side-menu-item>

      <side-menu-item
        v-if="$can('create_search', 'all')"
        route-name="list-vacancies"
        icon="ri-seedling-line"
        title="Plantando Oportunidades"
      ></side-menu-item>

      <side-menu-item
        v-if="$can('list_participants', 'all')"
        route-name="search-participants"
        icon="ri-user-search-line"
        title="Buscar Talento"
      ></side-menu-item>
    </ul>
    <div class="flex flex-col items-center w-full text-xs justify-center">
      <span class="mb-2" v-if="expanded"
        ><a
          href="https://sistemafamato.org.br/senarmt/politica-de-privacidade/"
          target="_blank"
          rel="noopener noreferrer"
          >Politica de Privacidade</a
        ></span
      >
      <span class="mb-2" v-if="expanded">
        <router-link :to="{ name: 'single-term' }">
          LGPD
        </router-link>
      </span>
      <span class="mt-3 pr-1" v-if="expanded">Versão {{ version }}</span>
    </div>
  </aside>
</template>

<script>
import SideMenuItem from './SideMenuItem';
import { mapGetters } from 'vuex';

export default {
  name: 'SideMenu',

  components: {
    SideMenuItem
  },

  data() {
    return {
      version: process.env.VUE_APP_VERSION
    };
  },

  computed: {
    ...mapGetters('menu', {
      expanded: 'expanded'
    }),

    logo() {
      return this.expanded
        ? require('../assets/images/logo-senar-monocromatica.png')
        : require('../assets/images/logo-arte-senar-monocromatica.png');
    },
    ...mapGetters('auth', {
      user: 'user'
    })
  }
};
</script>

<style scoped>
  ::-webkit-scrollbar {
  width: 1px;
}
.scroller {
  overflow-y: scroll;
  scrollbar-width: none;
}
</style>
