var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"h-full flex flex-col items-center justify-between py-4 bg-gradient-to-b from-green-1003 to-green-1002 text-white",class:_vm.expanded ? 'w-72' : 'w-20'},[_c('img',{staticClass:"py-6",class:_vm.expanded ? 'px-10' : 'px-4',attrs:{"src":_vm.logo,"alt":"Logotipo"}}),_c('ul',{staticClass:"scroller w-full flex-grow pl-2 overflow-y-scroll"},[(_vm.$can('create_search', 'all'))?_c('side-menu-item',{attrs:{"route-name":"search","icon":"ri-search-line","title":"Iniciar Colheita"}}):_vm._e(),(_vm.$can('list_associates', 'all'))?_c('side-menu-item',{attrs:{"route-name":"action-plan","icon":"ri-book-2-line","title":"Lista de Interessados"}}):_vm._e(),(_vm.user.role.name === 'associate')?_c('side-menu-item',{attrs:{"route-name":"favorites","icon":"ri-user-star-line","title":"Talentos Favoritos"}}):_vm._e(),(
        _vm.user.role.name === 'participant' ||
          _vm.user.role.name === 'Instructor' ||
          _vm.user.role.name === 'associate'
      )?_c('side-menu-item',{attrs:{"route-name":"dashboard","icon":"ri-dashboard-line","title":"Agenda de Cursos"}}):_vm._e(),(
        _vm.user.role.name === 'participant' || _vm.user.role.name === 'Instructor'
      )?_c('side-menu-item',{attrs:{"route-name":"search-vacancies","icon":"ri-user-search-line","title":"Buscar Vaga"}}):_vm._e(),(
        _vm.user.role.name === 'participant' || _vm.user.role.name === 'Instructor'
      )?_c('side-menu-item',{attrs:{"route-name":"my-vacancies","icon":"ri-star-s-fill","title":"Minhas Vagas"}}):_vm._e(),(_vm.$can('list_courses', 'all'))?_c('side-menu-item',{attrs:{"route-name":"course","icon":"ri-book-2-line","title":"Cursos"}}):_vm._e(),(_vm.user.role.name === 'administrator')?_c('side-menu-item',{attrs:{"route-name":"list-ads","icon":"ri-flag-line","title":"Banners"}}):_vm._e(),(_vm.$can('list_acceptances', 'all'))?_c('side-menu-item',{attrs:{"route-name":"list-terms","icon":"ri-file-text-line","title":"LGPD"}}):_vm._e(),(_vm.$can('list_associates', 'all'))?_c('side-menu-item',{attrs:{"route-name":"list-associates","icon":"ri-service-line","title":"Associados"}}):_vm._e(),(_vm.$can('regionals:read', 'all'))?_c('side-menu-item',{attrs:{"route-name":"list-regionals","icon":"ri-team-line","title":"Regionais"}}):_vm._e(),(_vm.$can('list_syndicates', 'all'))?_c('side-menu-item',{attrs:{"route-name":"list-syndicates","icon":"ri-building-line","title":"Sindicatos"}}):_vm._e(),(_vm.$can('create_search', 'all'))?_c('side-menu-item',{attrs:{"route-name":"list-vacancies","icon":"ri-seedling-line","title":"Plantando Oportunidades"}}):_vm._e(),(_vm.$can('list_participants', 'all'))?_c('side-menu-item',{attrs:{"route-name":"search-participants","icon":"ri-user-search-line","title":"Buscar Talento"}}):_vm._e()],1),_c('div',{staticClass:"flex flex-col items-center w-full text-xs justify-center"},[(_vm.expanded)?_c('span',{staticClass:"mb-2"},[_c('a',{attrs:{"href":"https://sistemafamato.org.br/senarmt/politica-de-privacidade/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Politica de Privacidade")])]):_vm._e(),(_vm.expanded)?_c('span',{staticClass:"mb-2"},[_c('router-link',{attrs:{"to":{ name: 'single-term' }}},[_vm._v(" LGPD ")])],1):_vm._e(),(_vm.expanded)?_c('span',{staticClass:"mt-3 pr-1"},[_vm._v("Versão "+_vm._s(_vm.version))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }