<template>
  <div>
    <button type="button" @click="notificationOpen = !notificationOpen, notificationopen()"
      class="relative z-300 h-12 w-12 focus:color-green-700 focus:outline-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="38" height="40">
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z"
          fill="rgba(78,175,110,1)" />
      </svg>
      <span v-if="!counternot == ''"
        class="absolute flex -mt-11 -ml-1 items-center justify-center bg-red-600 rounded-full w-6 h-6 text-sm text-white">
        {{ counternot }}
      </span>
    </button>
    <button v-if="notificationOpen" @click="notificationOpen = false, notificationopen()" tabindex="-1"
      class="fixed z-20 inset-0 h-full w-full bg-black opacity-50 cursor-default"></button>
    <div v-if="notificationOpen"
      class="absolute z-20 right-0 sm:left-0 mt-2 w-80 flex flex-col bg-white rounded-lg text-white max-h-96">
      <div class="block p-4 bg-gray-100 rounded-lg border-b border-white">
        <p class="font-bold text-green-700 text-xl">Notificações</p>
      </div>
      <div v-if="!notification.length" class="flex p-4 justify-center	bg-gray-100 rounded-lg border-b border-white">
        <span
          class="flex flex-col items-center px-4 py-3 transition-colors duration-300 transform border-b text-gray-600 text-center border-gray-100"><i
            class="ri-emotion-sad-line"></i>Você não tem novas notificações</span>
      </div>
      <div v-else class=" scroller overflow-x-hidden">
        <div v-for="(notification, index) in notification" :key="notification.id"
          v-on:click="readnotification(notification.id, notification.url, index)"
          class="flex items-center hover:bg-gray-300">
          <span class="bg-blue-600 rounded-full m-2 p-1"></span>
          <button
            class="flex flex-col items-start pl-2 py-3 w-full transition-colors duration-300 transform border-b border-gray-100">
            <p class="font-bold text-left text-base text-gray-600">{{ notification.title }}</p>
            <p class="text-sm font-semibold text-left text-gray-600">{{ notification.description }} </p>
            <p class="text-xs text-left text-gray-600">{{ notification.tempo }}.</p>
          </button>
        </div>
      </div>
      <div class="flex p-1 justify-center	bg-gray-100 rounded-lg border-b border-white">
        <router-link class="text-blue-500 hover:underline" :to="{ name: 'notifications' }">
          Ver todas as notificações
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios';


export default {
  name: 'notification',
  
  data() {
    return {
      notification: [],
      counternot: '',
      notificationOpen: false
    }
  },

  methods: {
    notificationopen() {
      var body = document.getElementById("body-dashboard");
      if (this.notificationOpen === true){
      body.style.overflow = 'hidden';
      }else {
      body.style.overflow = 'auto';
      }
    },

    readnotification(id, url, index) {
      if (url) {
        window.open(url, "_blank");
      }

      axios
        .post('/api/notificacao-view', {
          notification_id: id
        });
      this.counternot = this.counternot - 1;
      this.notification.splice(index, 1)

    },

    async fetchNotification() {
      await axios.get('/api/notificacao').then(({ data }) => {
        data.data.forEach(not => {
          if (not.visualized === 0) {
            this.counternot++;
            this.notification.push({
              id: not.id,
              title: not.title,
              description: not.description,
              tempo: not.tempo,
              visualized: not.visualized,
              url: not.url
            });
          }
        });
      });
    }
  },
  created: function () {
    this.fetchNotification();
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 1px;
}

.scroller {
  overflow-y: scroll;
  scrollbar-width: none;
}
</style>
