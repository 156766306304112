<template>
  <div class="w-full bg-gray-1002 h-screen flex overflow-hidden">
    <event-hub></event-hub>
    <side-menu></side-menu>
    <div
      class="w-full h-screen sm:justify-between flex flex-col overflow-y-auto"
      id="body-dashboard"
    >
      <header class="w-full flex justify-between items-center text-xl py-4">
        <button
          class="group bg-green-1003 pr-2 text-white focus:outline-none rounded-tr-3xl rounded-br-3xl"
          @click="toggleSideMenu"
        >
          <i
            :class="menuExpanded ? 'ri-arrow-right-line' : 'ri-arrow-left-line'"
            class="block text-2xl group-hover:transform group-hover:rotate-180 transition-all duration-150"
          ></i>
        </button>
        <account-dropdown :userAccess="userAccess" />
      </header>
      <div class="w-full flex flex-col flex-grow">
        <slot :userAccess="userAccess" />
      </div>
      <footer
        class="w-full px-4 py-1 hidden sm:flex sm:justify-between sm:items-center"
      >
        <div
          class="inline-flex text-sm text-center text-gray-600 lg:text-lg lg:text-left items-center"
        >
          Desenvolvido por ETIC - Sistema Famato/Senar-MT
          <i class="ri-copyright-line"></i> 2022. Todos os direitos reservados.
        </div>
        <div
          v-if="this.user.role.name && this.user.role.name !== 'participant'"
          class="flex"
        >
          <a
            href="mailto:dev@senarmt.org.br"
            class="mr-3 hover:bg-green-1002 hover:rounded-full text-2xl px-2 py-1 flex items-center justify-center hover:text-white transition-all"
          >
            <i class="ri-mail-line"></i>
          </a>
          <a
            href="tel:6539284842"
            class="hover:bg-green-1002 hover:rounded-full text-2xl px-2 py-1 flex items-center justify-center hover:text-white transition-all"
          >
            <i class="text-2xl ri-phone-line"></i>
          </a>
        </div>
      </footer>
    </div>

    <whatsapp-link></whatsapp-link>
  </div>
</template>

<script>
import AccountDropdown from '../components/AccountDropdown';
import EventHub from '@/components/EventHub';
import SideMenu from '../components/SideMenu';
import { mapActions, mapGetters } from 'vuex';
import WhatsappLink from '../components/WhatsappLink';
import moment from 'moment';
import axios from '@/utils/axios';

export default {
  name: 'DashboardLayout',

  components: {
    WhatsappLink,
    AccountDropdown,
    EventHub,
    SideMenu
  },

  data() {
    return {
      isOpen: false,
      userAccess: {
        status: null,
        access: {
          grant_access_at: '',
          revoke_access_at: '',
          diference: '',
          isRevoke: false
        },
        informations: {
          syndicate: {
            name: '',
            telephone: ''
          }
        }
      }
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    }),

    ...mapGetters('menu', {
      menuExpanded: 'expanded'
    })
  },

  methods: {
    ...mapActions('menu', {
      toggleSideMenu: 'toggle'
    }),

    fetchSyndicateInformation() {
      axios
        .post('/api/cadastrar-empresa/search-by-id', {
          id_associate: this.user.id
        })
        .then(({ data }) => {
          this.userAccess.informations.syndicate.name =
            data.data[0].syndicate.name;
          this.userAccess.informations.syndicate.telephone =
            data.data[0].syndicate.telephone;
        });
    },

    daysLeft() {
      let today = moment().format('YYYY-MM-DD');
      let revokeAccess = moment(
        this.user.revoke_access_at,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');

      today = moment(today);
      revokeAccess = moment(revokeAccess);

      this.userAccess.access.diference = revokeAccess.diff(today, 'days');
    },

    blockAccess() {
      if (this.user.grant_access_at != '' && this.user.revoke_access_at != '') {
        this.userAccess.access.isRevoke =
          moment().format('YYYY-MM-DD') >
          moment(this.user.revoke_access_at, 'DD/MM/YYYY').format('YYYY-MM-DD')
            ? true
            : false;
      }
    }
  },

  created() {
    const handleEscape = e => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.isOpen = false;
      }
    };

    this.userAccess.status = this.user.status.value;
    this.userAccess.access.grant_access_at = this.user.grant_access_at;
    this.userAccess.access.revoke_access_at = this.user.revoke_access_at;

    document.addEventListener('keydown', handleEscape);

    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', handleEscape);
    });

    this.fetchSyndicateInformation();
    this.daysLeft();
    this.blockAccess();
  }
};
</script>

<style scoped></style>
