<template>
  <li
    v-if="expanded"
    class="my-1 py-2 px-5 cursor-pointer rounded-tl-3xl rounded-bl-3xl hover:bg-gray-1002 hover:text-green-1002 transition-all duration-150 ease-in-out"
  >
    <router-link
      :to="{ name: routeName }"
      class="flex items-center w-full text-xl"
    >
      <i :class="icon" class="text-2xl pr-3"></i>
      {{ title }}
    </router-link>
  </li>
  <li
    v-else
    class="my-1 py-2 px-5 cursor-pointer rounded-tl-3xl rounded-bl-3xl hover:bg-gray-1002 hover:text-green-1002 transition-all duration-150 ease-in-out"
  >
    <router-link
      :to="{ name: routeName }"
      class="flex items-center w-full text-xl"
    >
      <i :class="icon" class="text-2xl pr-3"></i>
    </router-link>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SideMenuItem',

  props: {
    routeName: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters('menu', {
      expanded: 'expanded'
    })
  }
};
</script>

<style scoped></style>
