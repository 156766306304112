<template>
  <div class="relative">
    <div class="flex items-center">
      <button
        type="button"
        @click="isOpen = !isOpen"
        class="relative z-20 h-12 w-12 rounded-full overflow-hidden border-2 border-green-400 focus:border-green-700 focus:outline-none"
      >
        <img
          class="h-full w-full object-cover"
          :src="require('../assets/images/user.png')"
          alt=""
        />
      </button>
      <button
        v-if="isOpen"
        @click="isOpen = false"
        tabindex="-1"
        class="fixed z-10 inset-0 h-full w-full bg-black opacity-50 cursor-default"
      ></button>
      <div class="hidden sm:block px-4 py-1 w-64 leading-4">
        <p class="font-bold text-lg mb-2 text-green-700">{{ user.name }}</p>
        <p class="font-bold text-sm text-gray-600">
          {{ user.role.label }}
          <toggle-button class="float-right" :value="isAvailable" :width="110" :height="23" :font-size="14"
          :color="{ checked: '#007A6F', unchecked: '#A1A1A1' }"
          :labels="{ checked: 'Disponível', unchecked: 'Indisponível' }"
          @change="toggleAvailableStatus"
          v-tooltip="{
            content:
              'Altere sua disponibilidade para que recrutadores encontre seu currículo.',
            placement: 'bottom'
          }"
          v-if="user.role.name === 'participant' || user.role.name === 'Instructor'"
        />
          <span
            class="font-bold"
            v-if="
              $attrs.userAccess.status == 2 && user.role.name === 'associate'
            "
            >- Acesso Temporário</span
          >
        </p>
        <p
          class="text-sm font-light"
          v-if="
            !$attrs.userAccess.access.isRevoke &&
              user.role.name === 'associate' &&
              $attrs.userAccess.status == 2
          "
        >
          <i
            class="ri-information-line mr-1"
            v-tooltip="{
              content: `Seu acesso é provisório e após ${this.$attrs.userAccess.access.diference} dias suas ações
              dentro do sistema serão limitadas.
              <br>
              Entre em contato com o seu Sindicato para liberação do seu acesso definitivo.
              <br><br>
              Sindicato: ${this.$attrs.userAccess.informations.syndicate.name}
              <br>
              Contato: ${this.$attrs.userAccess.informations.syndicate.telephone}`,
              placement: 'left'
            }"
          ></i
          ><span>Restam {{ $attrs.userAccess.access.diference }} dias</span>
        </p>
      </div>
      <notification></notification>
    </div>
    <div
      v-if="isOpen"
      class="absolute z-20 right-0 sm:left-0 mt-2 w-64 flex flex-col bg-green-700 rounded-lg text-white overflow-hidden shadow-xl"
    >
      <div class="block sm:hidden px-4 py-2 bg-gray-100 border-b border-white">
        <p class="font-bold mb-4 text-green-700 text-xl">{{ user.name }}</p>
        <p class="font-bold text-gray-600 text-xl">{{ user.role.label }}</p>
      </div>
      <router-link
        :to="{ name: 'my-access' }"
        class="px-4 py-2 hover:bg-green-400 hover:cursor-pointer"
      >
        Meu Acesso
      </router-link>
      <router-link
        :to="{ name: 'my-profile' }"
        class="px-4 py-2 hover:bg-green-400 hover:cursor-pointer"
        v-if="
          user.role.name === 'participant' || user.role.name === 'Instructor'
        "
      >
        Meu Perfil
      </router-link>
      <router-link
        :to="{ name: 'my-address' }"
        class="px-4 py-2 hover:bg-green-400 hover:cursor-pointer"
        v-if="
          user.role.name === 'participant' || user.role.name === 'Instructor'
        "
      >
        Meu Endereço
      </router-link>
      <router-link
        :to="{ name: 'my-complete-curriculum' }"
        class="px-4 py-2 hover:bg-green-400 hover:cursor-pointer"
        v-if="$can('update_curriculum', 'all')"
      >
        Meu Currículo
      </router-link>
      <button
        type="button"
        class="text-left px-4 py-2 hover:bg-green-400 hover:cursor-pointer focus:outline-none"
        @click.prevent="logoutAndRedirect"
      >
        Sair
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from '@/utils/axios';
import notification from '../components/notification';


export default {
  name: 'AccountDropdown',

  components: {
    notification
  },

  data() {
    return {
      isOpen: false
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
      isAvailable: 'isAvailable'
    })
  },

  methods: {
    ...mapActions('auth', {
      logout: 'logout'
    }),

    toggleAvailableStatus() {
      axios
        .patch('/api/perfil/alterar-disponibilidade')
        .then(({ data }) => {
          this.$toast.success(data.data.message);
        })
        .catch(({ response }) => {
          Object.values(response.data.errors).forEach(error => {
            this.$toast.error(...error);
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    logoutAndRedirect() {
      this.logout()
        .then(({ data }) => {
          this.$toast.success(data.message);
          localStorage.removeItem('ComplementaryCurriculum');

          this.$router.push({
            name: 'home'
          });
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.message);
        });
    }
  },

  created() {
    const handleEscape = e => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.isOpen = false;
      }
    };

    document.addEventListener('keydown', handleEscape);

    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', handleEscape);
    });
  }
};
</script>

<style scoped></style>
